import React from 'react';
import { Link } from 'gatsby';

const CallToActionTwo = () => {
  return (
    <div className='cta-two'>
      <div className='container-fluid'>
        <div className='row no-gutters'>
          <div className='col-lg-12 thm-base-bg'>
            <div className='cta-two__single'>
              <div className='cta-two__icon'>
                <span>
                  {/* <i className='kipso-icon-professor'></i> */}
                </span>
              </div>
              <div className='cta-two__content'>
                <h2 className='cta-two__title'>LUTH Schools Admission</h2>
                <p className='cta-two__text'>
                  Admissions is now open for entry into these Schools in LUTH:
                  {/* Admissions is closed for entry into LUTH Schools */}
                </p>
                <br></br>
                <div className='row'>
                  <div
                    className='col-lg-4 cta-two__text'
                    style={{ marginBottom: 3 }}
                  >
                    Anaesthetic Technicians
                  </div>
                  <div
                    className='col-lg-4 cta-two__text'
                    style={{ marginBottom: 3 }}
                  >
                    School of Community Health
                  </div>
                  <div
                    className='col-lg-4 cta-two__text'
                    style={{ marginBottom: 3 }}
                  >
                    Federal School Of Biomedical Engineering
                  </div>
                  <div
                    className='col-lg-4 cta-two__text'
                    style={{ marginBottom: 3 }}
                  >
                    School Of Health Information Management
                  </div>
                  <div
                    className='col-lg-4 cta-two__text'
                    style={{ marginBottom: 3 }}
                  >
                    School Of Medical & Psychiatry Social Work
                  </div>
                  <div
                    className='col-lg-4 cta-two__text'
                    style={{ marginBottom: 3 }}
                  >
                    School Of Midwifery
                  </div>
                  <div
                    className='col-lg-4 cta-two__text'
                    style={{ marginBottom: 3 }}
                  >
                    School Of Nursing
                  </div>
                  <div
                    className='col-lg-4 cta-two__text'
                    style={{ marginBottom: 3 }}
                  >
                    School Of Post Basic Nursing
                  </div>
                </div>
                <Link to='/schools-list' className='thm-btn cta-two__btn'>
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
          {/* <div className='col-lg-6 thm-base-bg-2'>
            <div className='cta-two__single'>
              <div className='cta-two__icon'>
                <span>
                  <i className='kipso-icon-knowledge'></i>
                </span>
              </div>
              <div className='cta-two__content'>
                <h2 className='cta-two__title'>Join our community</h2>
                <p className='cta-two__text'>
                  There are many variations of passages of lore available but{' '}
                  <br /> the majority have suffered alteration in some form.
                </p>
                <a href='#none' className='thm-btn cta-two__btn'>
                  Start Learning
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CallToActionTwo;
